export interface NavProps {
  name: string;
  path: string;
  icon: string;
  gap?: boolean;
  badge?: boolean;
  class?: string;
}

const NavigationRoutes: NavProps[] = [
  {
    name: "home",
    path: "/dashboard/home",
    icon: "akar-icons:home",
  },
  {
    name: "services",
    path: "/dashboard/service",
    icon: "ic:outline-local-offer",
    class: "first-step",
  },
  {
    name: "Employee Availability",
    path: "/dashboard/schedule",
    icon: "akar-icons:schedule",
    class: "second-step",
  },
  {
    name: "staff",
    path: "/dashboard/staff",
    icon: "bi:person-badge",
    gap: true,
    class: "third-step",
  },
  {
    name: "CRM",
    path: "/dashboard/crm",
    icon: "ri:customer-service-2-line",
    gap: false,
    class: "third-step",
  },
  {
    name: "Loyalty feature",
    path: "/dashboard/loyalty-program",
    icon: "ri:customer-service-2-line",
    gap: false,
    class: "third-step",
  },
  {
    name: "reviews",
    path: "/dashboard/reviews",
    icon: "akar-icons:star",
  },
  {
    name: "appointments",
    path: "/dashboard/appointments",
    icon: "carbon:task-asset-view",
    badge: true,
    class: "fourth-step",
  },
  {
    name: "analytics",
    path: "/dashboard/analytics",
    icon: "ion:analytics-outline",
    gap: true,
  },
  {
    name: "gallery",
    path: "/dashboard/gallery",
    icon: "clarity:image-gallery-line",
  },
  {
    name: "Your FAQs",
    path: "/dashboard/faq",
    icon: "akar-icons:chat-question",
  },
  {
    name: "business info",
    path: "/dashboard/businessinfo",
    icon: "clarity:store-line",
    class: "fifth-step",
  },
];

export default NavigationRoutes;
